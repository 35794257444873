@import '../../../../scss/theme-bootstrap';

.popup-offer {
  .popup-offer-overlay-cbox & {
    width: 100%;
    height: 100%;
    &__outer-wrap {
      position: relative;
      width: 100%;
      height: 100%;
      padding: 15px 0 0 0;
      text-align: #{$ldirection};
      @include breakpoint($medium-up) {
        padding: 40px 25px 0;
      }
      .sign-in-component__birthday-program {
        .day-of-birth__collection-field {
          margin-bottom: 0;
          select {
            background-position: 96%;
          }
        }
      }
    }
    &__heading {
      font-family: $bb-sans;
      font-size: 26px;
      margin-bottom: 10px;
      text-transform: uppercase;
      letter-spacing: 0;
      line-height: 19px;
    }
    &__body {
      margin-bottom: 25px;
      font-weight: normal;
      color: $color-black-2;
      line-height: 1;
      letter-spacing: 0;
      font-family: $body-font;
      font-size: 16px;
    }
    &__email-input {
      text-transform: none;
    }
    input[type='submit'],
    input[type='text'] {
      text-align: center;
      display: block;
      width: 100%;
      max-width: 100%;
      letter-spacing: 1px;
    }
    input[type='text'] {
      @include input-placeholder {
        font-family: $body-font-bold;
        font-size: 15px;
        letter-spacing: 1px;
        color: $color-black;
        text-transform: uppercase;
      }
      font-family: $body-font-bold;
      font-size: 15px;
      color: $color-black;
      margin-bottom: 0;
      height: 45px;
      line-height: 45px;
      padding: 5px 10px;
    }
    input[type='submit'] {
      height: 43px;
      line-height: 43px;
      padding-top: 0;
      padding-bottom: 0;
    }
    &__sign-up-form {
      .sign-in-component {
        &__label {
          display: none;
        }
        &__birthday-program {
          display: flex;
          flex-direction: column;
          a.select-month,
          a.select-day,
          a.select-year {
            width: 48%;
            flex: 1;
          }
          a.select-month {
            margin-#{$rdirection}: 3%;
          }
          a.select-day {
            margin-#{$rdirection}: 0;
          }
        }
        &__birthday-program-copy {
          order: 1;
          margin: 5px 0 20px;
          font-size: 16px;
        }
      }
      .day-of-birth__collection-field {
        order: 2;
        display: flex;
        width: 100%;
      }
      .selectbox {
        float: #{$ldirection};
        &.select-month {
          margin-#{$rdirection}: 3%;
          width: 48%;
          padding-#{$rdirection}: 0;
          min-width: auto;
        }
        &.select-day {
          margin-#{$rdirection}: 0;
          width: 48%;
          padding-#{$rdirection}: 0;
          min-width: auto;
        }
        &.select-year {
          width: 34.5%;
          padding-#{$rdirection}: 0;
        }
      }
      &-error-messages {
        color: $color-red;
        span {
          display: block;
        }
      }
    }
    &__footer {
      font-size: 12px;
      color: $color-warm-gray;
      margin: 5px 0 25px;
      text-align: #{$ldirection};
      p {
        margin: 0;
      }
    }
  }
}

#cboxContent {
  .popup-offer-overlay-cbox & {
    .optional-info__header {
      border-top: 1px solid $color-very-light-pink;
      border-bottom: 0;
      padding-top: 25px;
      margin-bottom: 0;
      letter-spacing: 1px;
      padding-bottom: 0;
      text-indent: 0;
      font-size: 14px;
    }
  }
}
